import {cond, equals, pipe, T, type, map} from 'ramda';
import loadJS from '../../lib/load-external';
import ChatService from '../../types/ifc-chatservice';

const loadExternalPlugins = (chatService: ChatService) => {
	const pluginUrls = chatService.options.plugins || [];
	const processPlugin = cond([
		[
			plugin => equals(type(plugin), 'String'),
			async plugin => loadJS(plugin, document.body)
		],
		[plugin => equals(type(plugin), 'Function'), plugin => plugin(chatService)],
		[
			T,
			plugin => {
				console.error(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`${plugin} is not a valid plugin. Use a string or a function instead.`
				);
			}
		]
	]);

	window.chatService.register('initialize', async () =>
		Promise.all(
			pipe(
				map(plugin => {
					if (equals(type(plugin), 'String')) {
						try {
							// eslint-disable-next-line  @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
							const pluginFn = new Function(`return ${plugin}`)();
							if (equals(type(pluginFn), 'Function')) {
								return pluginFn;
							}
						} catch {
							return plugin;
						}
					}

					return plugin;
				}),
				map(processPlugin)
			)(pluginUrls)
		)
	);
};

export default loadExternalPlugins;
