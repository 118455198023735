const css = document.createElement('style');
css.type = 'text/css';
css.innerHTML = `
/* ----------------------------------------------
 * Generated by Animista on 2020-5-21 11:12:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-br
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-br {
  0% {
    visibility: visible;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-br {
  0% {
    visibility: visible;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2020-5-21 11:14:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * @animation scale-out-br
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-br {
  0% {
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    visibility: visible;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-out-br {
  0% {
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    visibility: visible;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}




/* ----------------------------------------------
 * Generated by Animista on 2020-7-8 10:34:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-bl
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-bl {
  0% {
    visibility: visible;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bl {
  0% {
    visibility: visible;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2020-7-8 10:36:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-bl
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-bl {
  0% {
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    visibility: visible;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-out-bl {
  0% {
     visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    visibility: visible;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}





.scale-in-br {
        -webkit-animation: scale-in-br 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-br 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.scale-out-br {
	-webkit-animation: scale-out-br 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: scale-out-br 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.scale-in-bl {
	-webkit-animation: scale-in-bl 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-bl 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.scale-out-bl {
	-webkit-animation: scale-out-bl 0.35s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: scale-out-bl 0.35s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

`;

document.getElementsByTagName('head')[0].append(css);
