import {cond, equals, is, T, F} from 'ramda';
import ChatService from '../../types/ifc-chatservice';
declare global {
	interface Window {
		__chatServiceConversion: number;
	}
}

const plugin = (chatService: ChatService) => {
	const trackConversion = cond([
		[
			value => equals(true, value) || is(Number, value) || is(String, value),
			(value: string | number) => {
				chatService.storage.set('Visitor Converted', value);
				chatService.telemetry.trackEvent('Visitor Conversion');

				chatService.logger.log(`Conversion tracked: ${value}`);
			}
		],
		[T, F]
	]);

	const trackLiveChat = cond([
		[
			is(String),
			tracker => (value: string | number) => {
				const eventHandler = () => {
					window.LC_API.trigger_sales_tracker(tracker, [
						{
							name: '__order_price',
							value
						}
					]);
				};

				chatService.events.on('LC_on_after_load', eventHandler);
			}
		],
		[T, F]
	]);

	chatService.register('ready', async () => {
		chatService.trackConversion = trackConversion;

		const options = window.chatServiceOptions;
		const lcTracker = trackLiveChat(options.livechatTracker);

		if (window.__chatServiceConversion) {
			trackConversion(window.__chatServiceConversion);
			lcTracker(window.__chatServiceConversion);
		}

		return Promise.resolve();
	});
};

export default plugin;
