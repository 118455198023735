import lcWidgetCommand from '../../lib/lc-widget-wrapper';
import loadJS from '../../lib/load-external';
import ChatService from '../../types/ifc-chatservice';

const LiveChatSnippet = 'https://cdn.livechatinc.com/tracking.js';

export default function (chatService: ChatService) {
	chatService.register('loadLiveChat', async () => {
		console.debug('loadLiveChat');

		chatService.events.on('LC_on_before_load', () => {
			const widgetState = chatService.storage.get('widget');

			lcWidgetCommand(window.LC_API)(widgetState.command);
		});

		return loadJS(LiveChatSnippet, document.body);
	});
}
