import ChatService from '../../types/ifc-chatservice';
import {
    LivechatWidgetReadyCallbackPayload,
    LivechatWidgetGreetingDisplayedCallbackPayload,
    LivechatWidgetCustomerStatusChangedCallbackPayload,
    LivechatWidgetCustomerDataSessionVariables,
} from '../../types/ifc-livechatwidget';

const updateSessionVarialbes = (variablesToSet: LivechatWidgetCustomerDataSessionVariables) => {
    window.LiveChatWidget.call('update_session_variables', {
        ...variablesToSet,
    });
};

// Chat Widget is ready
const onReady = (initialData: LivechatWidgetReadyCallbackPayload) => {
    const { customerData } = initialData;
    if (customerData.status !== 'invited') {
        updateSessionVarialbes({ greetingDisplayed: false });
    }
};

// Greeting has been displayed save to custom variables.
const onGreetingDisplayed = (greeting: LivechatWidgetGreetingDisplayedCallbackPayload) => {
    const { id } = greeting;
    updateSessionVarialbes({
        greetingDisplayed: true,
        greetingId: id,
    });
};

// Customer received an invitation but didn't start the chat
const onCustomerStatusChanged = ({
    status,
}: LivechatWidgetCustomerStatusChangedCallbackPayload) => {
    // Alongside of waiting for the onGreetingDisplayed event
    // we also listen for when the customer was invited to chat
    if (status === 'invited') updateSessionVarialbes({ greetingDisplayed: true });
};

const greetingsTracked = async (chatService: ChatService) => {
    chatService.register('ready', () => {
        if (window.LiveChatWidget) {
            window.LiveChatWidget.on('ready', onReady);
            // Both of the below two are needed for when LC
            // doesnt't fire the onGreetingDisplayed event
            // such as when you have a bubble theme
            window.LiveChatWidget.on('greeting_displayed', onGreetingDisplayed);
            window.LiveChatWidget.on('customer_status_changed', onCustomerStatusChanged);
        }
    });
    return Promise.resolve();
};

export default greetingsTracked;
