const loadJS = async (url: string, node: HTMLElement) => {
	const promise = new Promise((resolve, reject) => {
		const scriptTag = document.createElement('script');
		scriptTag.src = url;

		scriptTag.addEventListener(
			'load',
			() => {
				resolve(scriptTag);
			},
			false
		);

		scriptTag.addEventListener(
			'error',
			err => {
				reject(scriptTag);
				console.error(err);
			},
			false
		);

		node.append(scriptTag);
	});

	return promise;
};

export default loadJS;
