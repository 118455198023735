/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-underscore-dangle */
import ChatService, {
  ChatServiceEventOptionsEnum,
} from "../../types/ifc-chatservice";
import LC_API from "../../types/ifc-lcapi";
import LiveChatWidget from "../../types/ifc-livechatwidget";

const serialize = (data: Map<any, any>) => JSON.stringify([...data]);

const save = (data: Map<any, any>) => {
  sessionStorage.setItem("chatservice", serialize(data));
};

const deserialize = (arrayData: any) => JSON.parse(arrayData) || [];
const load = () => deserialize(sessionStorage.getItem("chatservice"));

const extractLiveChatData = (
  event: keyof typeof ChatServiceEventOptionsEnum,
  data: any
) => {
  switch (event) {
    case "LC_on_chat_started":
      window.chatService.storage.initializeLCProperties(
        window.LiveChatWidget,
        window.LC_API
      );

      return ["Agent Name", data.agent_name];

    case "LC_on_chat_window_opened": {
      window?.LiveChatWidget?.call("update_session_variables", {
        chatWidgetVisibility: "maximized",
      });
      return ["Widget State", "Maximized"];
    }
    case "LC_on_chat_window_minimized": {
      window?.LiveChatWidget?.call("update_session_variables", {
        chatWidgetVisibility: "minimized",
      });
      return ["Widget State", "Minimized"];
    }
    case "LC_on_rating_submitted":
      return ["Rating", data];

    case "LC_on_chat_ended":
      return ["Chat Running", false];

    default:
      return null;
  }
};

const brain = {
  data: new Map(),

  initialize() {
    brain.data = new Map(load());

    this._setupLiveChatListeners();
  },

  _setupLiveChatListeners() {
    [
      "LC_on_chat_window_opened",
      "LC_on_chat_window_minimized",
      "LC_on_chat_window_hidden",
      "LC_on_chat_started",
      "LC_on_chat_ended",
      "LC_on_ticket_created",
      "LC_on_prechat_survey_submitted",
      "LC_on_postchat_survey_submitted",
      "LC_on_rating_submitted",
      "LC_on_rating_comment_submitted",
      // eslint-disable-next-line unicorn/no-array-for-each
    ].forEach((event: keyof typeof ChatServiceEventOptionsEnum) =>
      window.chatService.events.on(event, payload => {
        const lcData = extractLiveChatData(event, payload);

        if (lcData === null) {
          return;
        }

        this.set(...lcData);
      })
    );

    window.chatService.events.once("LC_on_after_load", () => {
      this.initializeLCProperties(window.LiveChatWidget, window.LC_API);

      if (window.LiveChatWidget) {
        const { visibility } = window.LiveChatWidget.get("state");
        const { isReturning } = window.LiveChatWidget.get("customer_data");
        window.LiveChatWidget.call("update_session_variables", {
          chatWidgetVisibility: visibility,
          isReturningCustomer: isReturning,
        });
      }
    });
  },

  getTelemetryProperties: () => ({
    "Chat Running": brain.data.get("Chat Running"),
    "Visitor Engaged": brain.data.get("Visitor Engaged"),
    "Chat Id": brain.data.get("Chat Id"),
    "Visitor Id": brain.data.get("Visitor Id"),
    "Visitor Converted": Boolean(brain.data.get("Visitor Converted")),
  }),

  getTelemetryMetrics: () => ({
    "LC Page Views": brain.data.get("LC Page Views"),
    "LC Visits Number": brain.data.get("LC Visits Number"),
    "Visitor Conversion Value": brain.data.get("Visitor Converted") || 0,
    "Visitor Basket Value": brain.data.get("Visitor Basket") || 0,
  }),

  initializeLCProperties: (LiveChatWidget: LiveChatWidget, LC_API: LC_API) => {
    brain.set(
      "Chat Running",
      LiveChatWidget.get("customer_data").status === "chatting"
    );
    brain.set(
      "Visitor Engaged",

      LiveChatWidget.get("customer_data").status === "chatting" ||
        LiveChatWidget.get("customer_data").status === "queued"
    );
    brain.set("Chat Id", LiveChatWidget.get("chat_data").threadId);
    brain.set("Visitor Id", LiveChatWidget.get("customer_data").id);
    brain.set("LC Page Views", `${LC_API.get_page_views_number() || 0}`);
    brain.set("LC Visits Number", `${LC_API.get_visits_number() || 0}`);
  },

  set: (key: string, value: any) => {
    brain.data.set(key, value);
    save(brain.data);
  },

  get: (key: string) => brain.data.get(key),
};

export default function (chatService: ChatService) {
  chatService.register("pre_initialize", async () => {
    brain.initialize();
    // eslint-disable-next-line no-param-reassign
    chatService.storage = brain;

    return Promise.resolve();
  });
}
