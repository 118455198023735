/* eslint-disable object-shorthand, prefer-const, camelcase */

import ChatService from '../../types/ifc-chatservice';
import {pathOr} from 'ramda';

export default function (chatService: ChatService) {
	const assignCredentials = () => {
		const {license, group} = chatService.options.credentials;
		const options = {
			chat_between_groups: pathOr(
				false,
				['options', 'chat_between_groups'],
				window.chatServiceOptions
			),
			group,
			license
		};

		window.__lc = Object.assign({}, options, window.__lc);
	};

	const overrideCallbacks = () => {
		const callbacks = [
			'on_before_load',
			'on_after_load',
			'on_chat_window_opened',
			'on_chat_window_minimized',
			'on_chat_window_hidden',
			'on_chat_state_changed',
			'on_chat_started',
			'on_chat_ended',
			'on_message',
			'on_ticket_created',
			'on_prechat_survey_submitted',
			'on_postchat_survey_submitted',
			'on_rating_submitted',
			'on_rating_comment_submitted'
		];

		const options: any = callbacks.reduce((options, cb) => {
			const callback = (data: any = {}) => {
				const key: any = `LC_${cb}`;
				chatService.events.emit(key, data);
			};

			return Object.assign({}, options, {[cb]: callback});
		}, {});

		window.LC_API = Object.assign({}, window.LC_API || {}, options);
	};

	chatService.register('initialize', async () => {
		window.__lc = window.__lc || {};
		(function (n, __, c) {
			// eslint-disable-next-line unicorn/prevent-abbreviations
			let e: any = {
				_q: [],
				_h: null,
				_v: '2.0',
				on: function () {
					i(['on', c.call(arguments)]);
				},
				once: function () {
					i(['once', c.call(arguments)]);
				},
				off: function () {
					i(['off', c.call(arguments)]);
				},
				get: function () {
					if (!e._h) {
						throw new Error(
							"[LiveChatWidget] You can't use getters before load."
						);
					}

					return i(['get', c.call(arguments)]);
				},
				call: function () {
					i(['call', c.call(arguments)]);
					// Emit call events
					window.chatService.events.emit('LiveChatWidget_Call', arguments);
				}
			};
			function i(n: any) {
				return e._h ? e._h.apply(null, n) : e._q.push(n);
			}

			n.LiveChatWidget = n.LiveChatWidget || e;
		})(window, document, [].slice);

		return Promise.resolve();
	});

	chatService.register('initialize', async () => {
		console.debug('initializeLiveChat');

		assignCredentials();
		overrideCallbacks();

		return Promise.resolve();
	});
}
