/* eslint-disable func-names */
import ChatService from '../../types/ifc-chatservice';

interface EmmaData {
    href: string;
    optionSelector: string;
    quantitySelector: string;
    type: string;
}
declare global {
    interface Window {
        TCS_EMMA_DATA_MAP: EmmaData[];
        tcsCheckAddToCart: any;
    }
}

const plugin = (chatService: ChatService) => {
    chatService.register('ready', async () => {
        // Here we handle rich message button click
        // eslint-disable-next-line unicorn/consistent-function-scoping
        function onRichMessageButtonClicked(data: any) {
            const { postbackId } = data; // {type}-${option}-${quantity}
            if (postbackId && window.TCS_EMMA_DATA_MAP) {
                const splitted = postbackId && postbackId.split('-');
                const type = splitted && splitted[0];

                const product = window.TCS_EMMA_DATA_MAP.find(function (item) {
                    return item.type === type;
                });
                if (product) {
                    sessionStorage.setItem('tcs_product_from_rich_message_postback_id', postbackId);
                    if (!window.location.href.startsWith(product.href)) {
                        window.location.href = product.href;
                    } else {
                        window.tcsCheckAddToCart();
                    }
                }
            }
        }
        try {
            if (
                window.LiveChatWidget &&
                window.LiveChatWidget.on &&
                window.chatServiceOptions.group === 304 &&
                window.chatServiceOptions.license === '1509622'
            ) {
                window.LiveChatWidget.on('rich_message_button_clicked', onRichMessageButtonClicked);
            }
        } catch (error) {
            console.error(error);
        }
        return Promise.resolve();
    });
};

export default plugin;
