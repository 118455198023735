import { cond, is, pathOr, propOr, T } from 'ramda';
import ChatService from '../../types/ifc-chatservice';
// eslint-disable-next-line import/no-unassigned-import, unicorn/prefer-module
require('./animations');

// Change below line if you want to modify the default v3 theme
const DEFAULT_V3_THEME = 'Modern'; // Or "Smooth"

const themeCss = {
    Smooth: `
		height: 24px;
		bottom: 16px;
		width: 359.830px;
		border-radius: 0px 0px 6px 6px;
	`,
    Modern: `
		height: 31px;
		bottom: 0;
		width: 368.011px;
	`,
};

// This css is needed by default for LC3
const pwrCssLcVersion3 = ({ themeV3 = DEFAULT_V3_THEME, cssOverrideV3 = '' } = {}) => `
	right: 16px;
	background-color: #f0f0f0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding:0;
	${propOr(themeCss[DEFAULT_V3_THEME], themeV3, themeCss)}
	${window.LC_API.mobile_is_detected() ? `display: none;` : ''}
	${cssOverrideV3}
`;

const selectors = {
    v2: '#livechat-full',
    v3: '#chat-widget-container',
};

const getLcVersion = () => {
    if (document.querySelector(selectors.v2)) {
        return 'v2';
    }

    if (document.querySelector(selectors.v3)) {
        return 'v3';
    }

    return null;
};

// Only for v3
const hidePwrV3 = ({ chatAlignedV3 = 'right' } = {}) => {
    const node = document.querySelector(`${selectors.v3} #powered-by-tcs`);
    if (!node) return;
    if (chatAlignedV3 === 'left') {
        node.classList.remove('scale-in-bl');
        node.classList.add('scale-out-bl');
    } else {
        node.classList.remove('scale-in-br');
        node.classList.add('scale-out-br');
    }
};

const showPwrV3 = ({ chatAlignedV3 = 'right' } = {}) => {
    const node = document.querySelector(`${selectors.v3} #powered-by-tcs`);
    if (!node) return;
    if (chatAlignedV3 === 'left') {
        node.classList.remove('scale-out-bl');
        node.classList.add('scale-in-bl');
    } else {
        node.classList.remove('scale-out-br');
        node.classList.add('scale-in-br');
    }
};

const plugin = (chatService: ChatService) => {
    chatService.register('post_ready', async () => {
        const addLink = ({
            text = 'Powered by The Chat Shop',
            url = `https://www.thechatshop.com/?utm_source=chatlink&utm_medium=livechatclientwindow&utm_campaign=live_chat_plugin&utm_term=${window.chatServiceOptions.customer.name}`,
            color = 'gray',
            bottom = '4',
            fontSize = '10',
            fontFamily = '"Lucida Grande","Lucida Sans Unicode",Arial,Verdana,sans-serif',
            fontWeight = 'normal',
            themeV3,
            cssOverrideV3,
        }: {
            text: string;
            color: string;
            bottom: string;
            fontSize: string;
            fontFamily: string;
            fontWeight: string;
            url: string;
            chatAlignedV3: string;
            themeV3: string;
            cssOverrideV3: string;
        }) => {
            const lcVersion = getLcVersion();
            const node = document.querySelector(selectors[lcVersion]);

            if (!node) return;

            const poweredLink = document.createElement('a');
            const linkText = document.createTextNode(text);
            poweredLink.append(linkText);
            poweredLink.title = text;
            poweredLink.href = url;
            poweredLink.target = '_blank';
            poweredLink.id = 'powered-by-tcs';
            poweredLink.className = 'powered-by-tcs';
            node.append(poweredLink);

            const css = document.createElement('style');
            css.type = 'text/css';
            css.innerHTML = `.powered-by-tcs {
			  display: block;
              position: absolute;
              font-size: ${fontSize}px;
              padding: 2px;
              background-color: transparent;
              bottom: ${bottom}px;
              text-decoration: none;
              font-weight:${fontWeight};
              right: 20px;
              color: ${color};
              transition: all 0.3s;
			  font-family: ${fontFamily};
			  ${lcVersion === 'v3' ? pwrCssLcVersion3({ themeV3, cssOverrideV3 }) : ''}
			  ${
                  lcVersion === 'v3'
                      ? window.LiveChatWidget.get('state').visibility === 'maximized'
                          ? 'visibility: visible;'
                          : 'visibility: hidden;'
                      : ''
              };
			}
          .powered-by-tcs:hover{
              color:#326bc0;
          }`;
            node.append(css);
        };

        const pwrOptions = pathOr(false, ['chatServiceOptions', 'pwr'], window);

        if (!pwrOptions) {
            return Promise.resolve();
        }

        const poweredByEnabled = cond([
            [
                is(Object),
                async (options) => {
                    addLink(options);
                    return Promise.resolve();
                },
            ],
            [T, async () => Promise.resolve()],
        ]);

        chatService.events.on('LC_on_after_load', () => poweredByEnabled(pwrOptions));

        chatService.events.on('LC_on_chat_window_opened', () => {
            if (!window.LC_API.mobile_is_detected()) {
                showPwrV3(pwrOptions);
            }
        });
        chatService.events.on('LC_on_chat_window_minimized', () => {
            hidePwrV3(pwrOptions);
        });
        chatService.events.on('LC_on_chat_window_hidden', () => {
            hidePwrV3(pwrOptions);
        });

        return Promise.resolve();
    });
};

export default plugin;
