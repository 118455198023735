import BootyProc from '@thechatshop/booty-proc';
import {is, isEmpty, not, path, pathOr, pipe} from 'ramda';
import fetch from 'unfetch';

import plugins from './plugins';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Emitter = require('tiny-emitter');

const isPreviewMode = (remoteConfig: string) =>
	pipe(
		(url: string) => new URL(url),
		(url: URL) => url.searchParams.get(`preview-${remoteConfig}`)
	)(window.location.href);

const ChatService = function (license: string, group: number, options = {}) {
	const stages = ['initialize', 'authorize', 'loadLiveChat', 'ready'];
	Object.assign(this, new BootyProc(stages));

	this.logger = console;

	const setup = async () => {
		const remoteConfig = path(['chatServiceOptions', 'remoteConfig'], window);

		if (typeof remoteConfig === 'string' && remoteConfig.length > 20) {
			const remoteConfigUrl = isPreviewMode(remoteConfig)
				? `https://public-chatservice.s3.eu-west-2.amazonaws.com/preview/${remoteConfig}-chatservice-config-preview.json`
				: `https://public.chatservice.co/${remoteConfig}-chatservice-config.json`;

			const config = await fetch(`${remoteConfigUrl}`)
				.then(async response => response.json())
				.catch(() => {
					throw new Error("[ChatService] Couldn't load config");
				});

			if (config) {
				this.options = Object.assign({}, this.options, {...config});

				window.chatServiceOptions = Object.assign(
					window.chatServiceOptions,
					config
				);
			}
		}

		return plugins
			.reduce(async (promiseChain, plugin) => {
				return promiseChain.then(() => {
					plugin(this);
				});
			}, Promise.resolve())
			.then(() => {
				return this.run();
			})
			.catch(error => {
				if (error instanceof Error) {
					this.logger.error(error);
				} else {
					this.logger.log(error);
				}
			});
	};

	// const emitter: typeof  = Emitter;
	this.events = new Emitter();
	this.options = Object.assign({}, options, {
		credentials: {license, group}
	});

	// eslint-disable-next-line @typescript-eslint/no-floating-promises
	setup();
};

export default ChatService;
