import {cond, gte, T, F} from 'ramda';
import lcCommand from '../../lib/lc-widget-wrapper';
import ChatService from '../../types/ifc-chatservice';

const isPriorityGreaterOrEqualThanState = (
	priority: number,
	widgetState: {priority: number}
) => gte(priority, widgetState.priority);

const widget = (chatService: ChatService) => {
	let isChatWidgetNotLoaded = true;

	chatService.events.on('LC_on_after_load', () => {
		isChatWidgetNotLoaded = false;
	});

	const command = (command: 'hide' | 'minimize' | 'maximize') => (
		priority = 100
	) => {
		const widgetState = chatService.storage.get('widget');

		const handleChatWidgetCommand = cond([
			[
				isPriorityGreaterOrEqualThanState,
				() => {
					chatService.storage.set('widget', {command, priority});
				}
			],
			[T, F]
		]);

		const runOnChatWidgetLoaded = cond([
			[
				() => isChatWidgetNotLoaded,
				() => handleChatWidgetCommand(priority, widgetState)
			],
			[T, () => lcCommand(window.LC_API)(command)]
		]);

		runOnChatWidgetLoaded();
	};

	const hide = command('hide');
	const minimize = command('minimize');
	const maximize = command('maximize');
	return {hide, maximize, minimize};
};

export default function (chatService: ChatService) {
	chatService.register('pre_initialize', async () => {
		chatService.storage.set('widget', {command: false, priority: 0});
		chatService.widget = widget(chatService);

		return Promise.resolve();
	});
}
