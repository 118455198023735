import {cond, equals, T, F} from 'ramda';
import LC_API from '../types/ifc-lcapi';

const lcCommand = (LC_API: LC_API): any =>
	cond([
		[
			equals('hide'),
			() => {
				LC_API.hide_chat_window();
			}
		],
		[
			equals('minimize'),
			() => {
				LC_API.minimize_chat_window();
			}
		],
		[
			equals('maximize'),
			() => {
				LC_API.show_full_view();
			}
		],
		[T, F]
	]);

export default lcCommand;
