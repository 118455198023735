import ChatService from '../../types/ifc-chatservice';
import forbiddenAgents from './forbidden-agents';

const isBot = forbiddenAgents.reduce(
	(result, userAgent) => result || navigator.userAgent.includes(userAgent),
	false
);

const blockBots = (chatService: ChatService) => {
	chatService.register('authorize', async () => {
		const nextPromise = isBot
			? Promise.reject(new Error('Bot detected'))
			: Promise.resolve();

		return nextPromise;
	});
};

export default blockBots;
