import loadLiveChat from './loadLiveChat';
import initializeLiveChat from './initializeLiveChat';
import extendLiveChat from './extendLiveChat';
import blockBots from './blockBots';
import loadExternalPlugins from './loadExternalPlugins';
import storage from './storage';
import conversion from './conversion';
import widget from './widget';
import poweredBy from './poweredBy';
import greetingsTracker from './greetingsTracker';
import emma from './emma';

export default [
    storage,
    widget,
    conversion,
    initializeLiveChat,
    loadLiveChat,
    extendLiveChat,
    blockBots,
    poweredBy,
    greetingsTracker,
    emma,
    loadExternalPlugins,
];
